function checkFields(form) {
    var inputs = form.find('.toValidate').not('[type="submit"],[type="button"],[type="reset"]'); 

    var filled = inputs.filter(function(){
        return $.trim($(this).val()).length > 0;
    });
    
    if(filled.length === 0) {
        return false;
    }
    
    return true;
}

$(function(){
    $('#searchForm').on('submit',function(e){
        var oneFilled = checkFields($(this));
        if (!oneFilled) {
            e.preventDefault();
            alert('Please fill at least one field.'); 
        }
    });
});